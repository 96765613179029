import React from 'react';

function Header(props) {
  // console.log('PROPS:', props);
  
  return (
    <div id='header'>
      DRUM MACHINE PROJECT
    </div>
  );
}

export default Header;
