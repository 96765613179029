import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';

function DrumPad(props) {
  // console.log('PROPS:', props);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    // Specify how to clean up after this effect:
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [props]);

  const handleKeyPress = (e) => {
    if (props) {
      // console.log('props.power:', props.power);
      if (props.power === 1) {
        if (e.keyCode === props.data['keyCode']) {
          playSound(props.data);
        }
      } else {
        props.setSound('');
      }
    }
    // console.log(sound['keyCode']);
  };

  const handleClick = () => {
    // console.log(sound['keyCode']);
    if (props.power === 1) {
      playSound(props.data);
    } else {
      props.setSound('');
    }
  };

  function playSound() {  // Pasar esta función a a DrumMachine.js ##################
    if (props) {
      // console.log('props.volume:', props.volume);
      // Animate DrumPad
      let drumPadID = 'drum-pad-' + props.data['id'];
      let originalBGColor = animatePad(drumPadID, '#30475E');
      setTimeout(() => animatePad(drumPadID, originalBGColor), 100);
      // Play audio sound
      const audio = document.getElementById(props.data['keyTrigger']);
      audio.volume = (props.volume / 100);
      audio.currentTime = 0;
      audio.play();
      // Setting sound name for displaying in DrumControls component
      props.setSound(props.data['id']);
    }
  }

  function animatePad(drumPadID, hexBGColor) {
    let divDrumPad = document.getElementById(drumPadID);
    let originalBGColor = divDrumPad.style.backgroundColor;
    divDrumPad.style.backgroundColor = hexBGColor;
    return originalBGColor;
  }

  return (
    <div 
      className='drum-pad' 
      id={ 'drum-pad-' + props.data['id'] }
      onClick={ handleClick }
    >
      <audio 
        className='clip' 
        id={ props.data['keyTrigger'] } 
        src={ props.data['url'] }
      >
      </audio>
      <Button 
        variant='link' 
        // onClick={ handleClick }
      >
        { props.data['keyTrigger'] }
      </Button>
    </div>
  );
}

export default DrumPad;
