import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import logo from '../images/logo.png';
import SliderSimple from './SliderSimple';

function DrumControls(props) {
  // console.log('PROPS:', props);
  
  return (
    <div id='drum-controls'>
      <Container fluid="md">
        <Row>
          <Col>
            <Image src={logo} alt='unfinited.work' title='unfinited.work' fluid={true} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SliderSimple 
              id='power-control' 
              label='ON / OFF' 
              min={ 0 } 
              max={ 1 } 
              value={ props.power } 
              handleInputChange={ props.setPower } 
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>SOUND NAME</label>
            {/* <input type='text' id='display' readOnly value={ props.sound }></input> */}
            <p id='display'>{ props.sound }</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <SliderSimple 
              id='volume-control' 
              label='VOLUME' 
              min={ 0 } 
              max={ 100 } 
              value={ props.volume } 
              handleInputChange={ props.setVolume } 
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SliderSimple 
              id='bank-selector-control' 
              label='BANK 1 / BANK 2' 
              min={ 1 } 
              max={ 2 } 
              value={ props.bankNumber } 
              handleInputChange={ props.setBankNumber } 
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DrumControls;
