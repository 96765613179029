import React from 'react';
import DrumMachine from './DrumMachine';

function Container(props) {
  // console.log('PROPS:', props);
  
  return (
    <div id='container'>
      <DrumMachine />
    </div>
  );
}

export default Container;
