import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DrumPad from './DrumPad';

function DrumPads(props) {
  // console.log('PROPS:', props);
  // console.log('props.power:', props.power);
  let bank = props.bank;
  // console.log('DrumPads BANK:', bank); // Se ejecuta dos veces, probar en prodccion #########
  // console.log(bank[0]);

  // Enhance DrumPad creation looping through array
  return (
    <div id='drum-pads'>
      {
        bank && 
        <Container>
          <Row>
            <Col className='drum-pads-col'>
              <DrumPad 
                data={ bank[0] } 
                setSound={ props.setSound } 
                power={ props.power } 
                volume={ props.volume } 
              />
            </Col>
            <Col className='drum-pads-col'>
              <DrumPad 
                data={ bank[1] } 
                setSound={ props.setSound } 
                power={ props.power }  
                volume={ props.volume } 
              />
            </Col>
            <Col className='drum-pads-col'>
              <DrumPad 
                data={ bank[2] } 
                setSound={ props.setSound } 
                power={ props.power } 
                volume={ props.volume } 
              />
            </Col>
          </Row>
          <Row>
            <Col className='drum-pads-col'>
              <DrumPad 
                data={ bank[3] } 
                setSound={ props.setSound } 
                power={ props.power } 
                volume={ props.volume } 
              />
            </Col>
            <Col className='drum-pads-col'>
              <DrumPad 
                data={ bank[4] } 
                setSound={ props.setSound } 
                power={ props.power } 
                volume={ props.volume } 
              />
            </Col>
            <Col className='drum-pads-col'>
              <DrumPad 
                data={ bank[5] } 
                setSound={ props.setSound } 
                power={ props.power } 
                volume={ props.volume } 
              />
            </Col>
          </Row>
          <Row>
            <Col className='drum-pads-col'>
              <DrumPad 
                data={ bank[6] } 
                setSound={ props.setSound } 
                power={ props.power } 
                volume={ props.volume } 
              />
            </Col>
            <Col className='drum-pads-col'>
              <DrumPad 
                data={ bank[7] } 
                setSound={ props.setSound } 
                power={ props.power } 
                volume={ props.volume } 
              />
            </Col>
            <Col className='drum-pads-col'>
              <DrumPad 
                data={ bank[8] } 
                setSound={ props.setSound } 
                power={ props.power } 
                volume={ props.volume } 
              />
            </Col>
          </Row>
        </Container>
      }
    </div>
  );
}

export default DrumPads;
