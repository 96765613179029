import React from 'react';

function SliderSimple(props) {
  // console.log('PROPS:', props);

  const handleInput = event => {
    // console.log(event.target.value);
    props.handleInputChange(parseInt(event.target.value));
  };
  
  return (
    <div id='slider-simple-container'>
      {/* <label>{ props.label } = { props.value }</label> */}
      <label>{ props.label }</label>
      <input 
        type='range' 
        min={ props.min } 
        max={ props.max } 
        value={ props.value } 
        className='slider-simple' 
        id={ props.id }
        onInput={ handleInput }
      ></input>
    </div>
  );
}

export default SliderSimple;
