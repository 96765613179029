import React from 'react';
import DrumPads from './DrumPads';
import DrumControls from './DrumControls';

function Display(props) {
  // console.log('PROPS:', props);
  
  return (
    <div id='drum-machine-display'>
      <DrumPads 
        bank={ props.bank } 
        power={ props.power } 
        setSound={ props.setSound }
        volume={ props.volume } 
      />
      <DrumControls 
        power={ props.power } 
        setPower={ props.setPower } 
        volume={ props.volume } 
        setVolume={ props.setVolume } 
        bankNumber={ props.bankNumber } 
        setBankNumber={ props.setBankNumber } 
        sound={ props.sound }
      />
    </div>
  );
}

export default Display;
